<template>
  <div class="local-cabinet">
    <div class="local-cabinet__bg-wrapper local-cabinet__top">
      <span class="local-cabinet__bg"></span>
      <div class="local-cabinet__bg-content">
        <Header />
        <div class="local-cabinet__content">
          <Title>Личный кабинет</Title>
          <Tabs
            :active-tab="Number(activeTab)"
            @change-active-tab="changeActiveTab"
          />
        </div>
      </div>
    </div>
    <Footer />
    <Loader v-if="false" />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import Title from '@/components/gui/title/Title';
import Loader from '@/components/views/main/loader/Loader.vue';

import login from '@/api/login';

import './local-cabinet.scss';

export default {
  name: 'LocalCabinet',
  components: {
    Header,
    Title,
    Loader,
    Tabs: () => import('@/components/views/local-cabinet/tabs/Tabs'),
    Footer: () => import('@/components/uniq/footer/Footer')
  },
  data() {
    return {
      activeTab: 0
    };
  },
  methods: {
    changeActiveTab(active) {
      this.activeTab = active;
    },
    async submit() {

      const { Success, objects, found, found_all } = await login({
        email: this.email,
        password: this.password
      });

      console.log(Success, objects, found, found_all);
    }
  }
};
</script>
